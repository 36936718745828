import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Network, ConnectionStatus } from '@capacitor/network'
import type { PluginListenerHandle } from '@capacitor/core';
import { BehaviorSubject, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService implements OnDestroy {

  public online$ = new BehaviorSubject<boolean>(false);
  private networkListenerHandle: PluginListenerHandle;

  constructor(
    private zone: NgZone
  ) {
    this.networkListenerHandle = Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      this.handleNetworkChange(status);
    });
    // Emit the initial status
    this.emitCurrentStatus();
  }

  public isOnline(): boolean {
    return this.online$.getValue();
  }

  private emitCurrentStatus() {
    from(Network.getStatus()).subscribe((status) => this.handleNetworkChange(status));
  }

  private handleNetworkChange(status: ConnectionStatus) {
    this.zone.run(() => this.online$.next(status.connected));
  }

  ngOnDestroy()  {
    if (this.networkListenerHandle) {
      Network.removeAllListeners();
    }
  }
}
