export enum DocumentTypes {
  HomePage = 'fraserAppHomePage',
  LandingPage = 'fraserAppLandingPage',
  InformationPage = 'fraserAppInformationPage',
  PointOfInterestPage = 'fraserAppPointOfInterestPage',
  ItineraryPage = 'fraserAppItineraryPage',
  MapPage = 'fraserAppMapPage',
  PhotoGalleryPage = 'fraserAppPhotoGalleryPage',
  SearchPage = 'fraserAppSearchPage',
  FormPage = 'fraserAppFormPage'
}
