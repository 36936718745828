import { Injectable } from '@angular/core';
import { ContentLoaderService } from './content-loader.service';
import { IPage } from 'src/app/models/page.model';
import { DocumentTypes } from 'src/app/models/document-types.enum';

@Injectable({
  providedIn: 'root'
})
export class ContentQueryService {

  constructor(private contentLoaderService: ContentLoaderService) { }

  public getPage(id: number): IPage {
    return this.contentLoaderService.manifest.pages.filter((page) => page.id === id )[0];
  }

  public getPageByUdi(udi: string) {
    return this.contentLoaderService.manifest.pages.filter((page) => page.udi === udi)[0];
  }

  public getPageByDocumentType(documentType: DocumentTypes): IPage {
    const results = this.contentLoaderService.manifest.pages.filter((page) => page.documentType === documentType);
    if (results && results.length > 0) {
      return results[0];
    } else {
      return null;
    }
  }

  public getPagesByDocumentType(documentType: DocumentTypes): IPage[] {
    return this.contentLoaderService.manifest.pages.filter((page) => page.documentType === documentType);
  }

  public getPageByAlias(alias: string): IPage {
    return this.contentLoaderService.manifest.pages.filter((page) => page.alias === alias)[0];
  }

  public getAllPages(): IPage[] {
    return this.contentLoaderService.manifest.pages;
  }

  // Get all Poi pages in an itinerary.
  getItineraryPoiPages(itineraryPOIs: number[]): IPage[] {
    const itineraryPages: IPage[] = [];
    for (const id of itineraryPOIs) {
      const page: IPage = this.getPage(id);
      itineraryPages.push(page);
    }
    return itineraryPages;
  }

  // Get all specifically related pages and if this is a poi page all itinerary pages that it belongs to.
  getAllRelatedPages(page: IPage): number[] {

    let pages: number[] = [];

    // get all itinerary pages that referene this poi (if any).
    if (page.documentType === DocumentTypes.PointOfInterestPage) {
      const itineraryPages = this.getPagesByDocumentType(DocumentTypes.ItineraryPage);
      if (itineraryPages) {
        for (const itineraryPage of itineraryPages) {
          if (itineraryPage.itineraryPOIs.find(id => (id === page.id))) {
            pages.push(itineraryPage.id);
          }
        }
      }
    }

    if (page.relatedPages) {
      pages = pages.concat(page.relatedPages);
    }

    pages = [... new Set(pages)]; // remove duplicates

    return pages;
  }

  safeGetPage(id: number): IPage {
    const page: IPage = this.getPage(id);
    if (page) {
      return page;
    } else {
      return {
        id,
        name: 'Error',
        description: 'Unable to find page with id: ' + id + '. It may have been removed.'
      } as IPage;
    }
  }
}
