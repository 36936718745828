import { Component, OnInit } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { ContentQueryService } from 'src/app/services/content/content-query.service';
import { ContentLoaderService } from 'src/app/services/content/content-loader.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Capacitor } from '@capacitor/core';
import { environment } from '../environments/environment';
import { NavigationService } from './services/navigation/navigation.service';
import { DocumentTypes } from './models/document-types.enum';
import { IPage } from './models/page.model';
import { StorageService } from './services/storage/storage.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { NetworkService } from './services/network/network.service';
import { ErrorService } from './services/error/error.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public env = environment;
  public appPageMenuItems = [];
  public autoRefresh = true;
  public isNative: boolean;

  public online$ = this.networkService.online$;
  public initialLoadCompleted$ = this.contentLoaderService.initialLoadCompleted$;

  constructor(
    private platform: Platform,
    private screenOrientation: ScreenOrientation,
    public contentLoaderService: ContentLoaderService,
    private contentQueryService: ContentQueryService,
    private toastController: ToastController,
    private networkService: NetworkService,
    private navigationService: NavigationService,
    private errorService: ErrorService,
    private storageService: StorageService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {

    this.platform.ready().then(() => {
      SplashScreen.hide();
      if (Capacitor.isNative) {
        StatusBar.setStyle({ style: Style.Dark });
      }
    });

    this.platform.ready().then(async () => {
      if (Capacitor.isNative) {
        await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
    });
  }

  async ngOnInit() {
    this.online$.subscribe();
    this.isNative = Capacitor.isNative;
    this.autoRefresh = await this.storageService.getAutoRefreshContentStatus();
    this.errorService.showPendingErrorMessageToast();
    this.initialLoadCompleted$.subscribe((completed) => this.setAppPageMenuItems(completed));
    await this.contentLoaderService.checkInitialLoadCompleted();
  }

  public async toggleAutoRefresh() {
    this.autoRefresh = !this.autoRefresh;
    this.storageService.setAutoRefreshContentStatus(this.autoRefresh);
    let toastMessage = '';
    if (this.autoRefresh) {
      toastMessage = 'Content auto refresh enabled. Content will automatically be updated whenever the app is restarted.';
    } else {
      toastMessage = 'Content auto refresh disabled. Pull down to refresh content manually.';
    }
    const toast = await this.toastController.create({
      color: 'primary',
      duration: 4000,
      message: toastMessage,
      position: 'top',
      buttons: [
        { text: 'Ok', role: 'cancel'}
      ]
    });
    toast.present();
  }

  private async setAppPageMenuItems(initialLoadCompleted: boolean) {
    this.appPageMenuItems = [
      {
        title: 'Home',
        url: '/home',
        icon: 'fa-home',
        disabled: false
      }
    ];

    if (initialLoadCompleted) {
      // Add menu items for each of the home page tiles
      const homePage = this.contentQueryService.getPageByDocumentType(DocumentTypes.HomePage);

      if (homePage) {
        for (const tilePageId of homePage.relatedPages) {
          const tilePage: IPage = this.contentQueryService.getPage(tilePageId);
          this.appPageMenuItems.push(
            {
              title: tilePage.name,
              url: this.navigationService.getPageUrl(tilePage),
              icon: tilePage.pageIcon,
              disabled: false
            });
        }
      }
    } else {
      // We don't have our content for the menu pages yet
      this.appPageMenuItems[0].disabled = true;  // showing just home page but it's disabled
    }
  }

  public isSplashPage() {
    return this.router.url.includes('/splash');
  }
}
