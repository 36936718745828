import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IMedia } from '../../models/media.model';
import { Filesystem, Directory, Encoding, WriteFileOptions, FileInfo, StatResult } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})
export class MediaLoaderService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  /**
   * Ensure the root level media folder exists so we don't get
   *   'Unable to write media file Error: Current directory does already exist.'
   * from the Filesystem plugin when async file downloads are done
   */
  public async ensureMediaFolderExists() {
    try {
      await Filesystem.mkdir({
        path: 'media',
        directory: Directory.Data
      });
      console.log('created media folder');
    } catch (ex) {} // ignore error thrown if folder already exists.
  }

  public async deleteMediaFile(media: IMedia) {
    try {
      const path = '/media/' + media.fileName;
      await Filesystem.deleteFile({
        path, // Get error deleting with localUri in web :( so recreate the file path manually and delete that
      });
      console.log('deleted media file ' + media.fileName);
    } catch (ex) {
      console.log(`could not delete file ${(media ? media.fileName : 'unknown')}, due to error ${ex}`);
    } // ignore error.
  }

  /**
   * Download the media file from the server and update the mediaitem properties
   * @param mediaItem the details of the media item to be downloaded
   * @param force force the file to be downloaded even if it already exists.
   */
  public async downloadMediaFile(mediaItem: IMedia, force = false): Promise<IMedia> {
    mediaItem.fileExtension = mediaItem.fileName.substr(mediaItem.fileName.lastIndexOf('.') + 1).toLowerCase();
    mediaItem.binaryFile = (mediaItem.fileExtension !== 'kml');
    mediaItem.dataUrlPrefix = this.getDataUrlPrefix(mediaItem.fileExtension);

    // Check if we have a local file already for this media item.
    let statResult: StatResult = null;
    let existingFileLocalUri = '';
    try {
      statResult = await Filesystem.stat({
        path: 'media/' + mediaItem.fileName,
        directory: Directory.Data
      });
      existingFileLocalUri = statResult && statResult.size > 0 ? statResult.uri : null;
    } catch {} // ignore error thrown if file doesn't exist on web impl.

    // Download remote file if we are forced to or don't have a local copy yet.
    if (force || !existingFileLocalUri) {

      const fileWriteOptions: WriteFileOptions = {
        path: 'media/' + mediaItem.fileName,
        recursive: true,
        data: null,
        directory: Directory.Data
      };

      if (!mediaItem.url) {
        // No url means a content error in the CMS or serverside, we will ignore and continue processing the blank item.
        // This effectively loads a blank media item but will not stop the app and the rest of the media download.
        // The problem will be able to be fixed at the cms level and once fixed a refresh will bring the data into the app.
        console.log('Warning - no url for media item ' + mediaItem.id);
      }

      if (mediaItem.binaryFile) {
        // We are dealing with binary data, use base64 encoding which is the default for capacitor filewriting.
        const mediaBlob = await this.httpClient.get(mediaItem.url, {responseType: 'blob'}).toPromise();
        fileWriteOptions.data = await this.convertBlobToBase64(mediaBlob);
        mediaItem.portrait = await this.checkImageOrientation(mediaItem, fileWriteOptions.data);
      } else {
        // This is text data, use UTF8 encoding and write the text only
        fileWriteOptions.encoding = Encoding.UTF8;
        fileWriteOptions.data = await this.httpClient.get(mediaItem.url, { responseType: 'text' }).toPromise();
      }

      try {
        // Now write it to the Filesystem at the required location.
        const result = await Filesystem.writeFile(fileWriteOptions);
        mediaItem.localUri = result.uri;
        console.log(`Downloaded media item to local file ${mediaItem.localUri}`);
      } catch (ex) {
        console.error('Unable to write media file ', ex);
      }
    } else {
      mediaItem.localUri = existingFileLocalUri;
      console.log(`already have media item ${mediaItem.fileName} ...skipping`);
    }
    return mediaItem;
  }

  // Download all of the media items in a list
  public async downloadMediaFiles(mediaList: IMedia[], force: boolean, progressCallback: (percent: number) => void) {
    const startTime = performance.now();
    const mediaRequestPromises = [];
    let ctr = 0;

    await this.ensureMediaFolderExists();

    for (const item of mediaList) {
      // Download the media item file if we haven't already downloaded a local copy
      mediaRequestPromises.push(this.downloadMediaFile(item, force).then(
        () => {
          // update progress
          const percentComplete = Math.round(((++ctr) / mediaList.length) * 100);
          progressCallback(percentComplete);
        }
      ));
    }
    await Promise.all(mediaRequestPromises);

    const timeTaken = (Math.round((performance.now() - startTime)) / 1000).toFixed(2);
    console.log(`download of ${mediaList.length} media files took ${timeTaken} sec`);
  }

  private async checkImageOrientation(mediaItem: IMedia, base64Blob: string): Promise<boolean> {
    if (mediaItem.fileExtension === 'jpg' || mediaItem.fileExtension === 'png') {
      try {
        const imgData: string = base64Blob.split(',')[1]; // Remove the existing dataUrlPrefix which will be application/octet or similar...
        const resourceUrl: string = mediaItem.dataUrlPrefix + imgData;
        const dimensions = await this.getImageDimensions(resourceUrl);

        // Image is considered portrait if its higher than it is wide
        return dimensions.h > dimensions.w;
      } catch (ex) {
        console.log(`Unable to get dimensions for image mediaItem ${mediaItem.fileName}`);
      }
    }
    // Not an image or unable to get dimensions
    return false;
  }

  /// Get the dimensions of a base64 encoded image.
  private getImageDimensions(imageResourceUrl): Promise<{w, h}> {
    return new Promise ((resolved, rejected) => {
      const i = new Image();
      i.onload = () => {
        resolved({w: i.naturalWidth, h: i.naturalHeight});
      };
      i.src = imageResourceUrl;
    });
  }

  /**
   * List the contents of a folder in the apps DATA directory
   * @param folderName the name of the folder in the DATA directory to be listed
   */
  public async getFolderContents(folderName: string): Promise<FileInfo[]> {
    try {
      const ret = await Filesystem.readdir({
        path: folderName,
        directory: Directory.Data
      });
      console.log('found ' + ret.files.length + ' files in ' + folderName + ' folder.');
      return ret.files;
    } catch (ex) {
      console.error('Unable to read dir', ex);
      return null;
    }
  }

  // Downloaded blobs need to be stored as Base64 files
  private async convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Get the data url prefix by file extension.
   * @param extension the file extension to get the data url prefix for eg png pdf etc
   */
  private getDataUrlPrefix(extension: string): string {

    let mimeType = '';

    switch (extension) {
      case 'png':
        mimeType = 'image/png';
        break;
      case 'jpg':
      case 'jpeg':
        mimeType = 'image/jpeg';
        break;
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      default:
        mimeType = 'image/png'; // assume an image
    }
    return extension === 'kml' ? '' : `data:${mimeType};base64,`;
  }

  // Analyse the curent manifest media list and compare with a new manifest media list, identifying the changes.
  public getMediaChanges(currentMediaList: IMedia[], newMediaList: IMedia[]): { downloads: IMedia[], deletes: IMedia[] } {

    const changes = {
      downloads: [],
      deletes: []
    };

    for (const newItem of newMediaList) {
      let currentItem: IMedia = null;
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < currentMediaList.length; j++) {
        if (newItem.id === currentMediaList[j].id) {
          currentItem = currentMediaList[j];
          break;
        }
      }
      if (currentItem) { // found and existing item
        if (newItem.updateDate > currentItem.updateDate) {
          changes.downloads.push(newItem);
        }
      } else { // Not found so must be a new item
        changes.downloads.push(newItem);
      }
    }

    // Now check and see if any media has been deleted.
    for (const currentItem of currentMediaList) {
      let found = false;
      for (const newItem of newMediaList) {
        if (newItem.id === currentItem.id) {
          found = true;
          break;
        }
      }
      if (!found) {
        changes.deletes.push(currentItem);
      }
    }

    return changes;
  }
}
