import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../environments/environment';
import { IMedia } from '../../models/media.model';
import { IPage } from '../../models/page.model';
import { IManifest, IManifestDto } from '../../models/manifest.model';
import { IManifestResponse } from '../../models/manifest-response.model';
import { MediaLoaderService } from '../media/media-loader.service';
import { LatLng } from '../../models/latlng.model';
import { BehaviorSubject } from 'rxjs';
import { Platform, ToastController } from '@ionic/angular';
import { ImageSize } from './image-size.enum';
import { NavigationExtras } from '@angular/router';
import { NavigationService } from '../navigation/navigation.service';
import { SystemPageTypes } from 'src/app/models/system-page-types.enum';
import { NetworkService } from '../network/network.service';
import { RefreshStatus } from './refresh-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ContentLoaderService {

  public manifest: IManifest;

  private manifestUrl = environment.apiBaseUrl + '/GetAppContentManifest';

  private initialLoadCompleted = new BehaviorSubject<boolean>(false);
  public  initialLoadCompleted$ = this.initialLoadCompleted.asObservable();

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private mediaLoaderService: MediaLoaderService,
    private platform: Platform,
    private toastController: ToastController,
    private networkService: NetworkService
    ) { }

  public async checkInitialLoadCompleted(): Promise<boolean> {
    const initialLoadCompleted = await this.storageService.getInitialLoadCompletedStatus();
    if (initialLoadCompleted && !this.manifest) {
      // Retrieve content from local storage (we have the media files already).
      this.setManifest(await this.storageService.getManifest());
    }
    this.initialLoadCompleted.next(initialLoadCompleted);
    return initialLoadCompleted;
  }

  /**
   * Load content from remote server or from local storage if available
   * @returns true if the content is/was loaded, false if there was an error loading content
   */
  public async loadContent(forceReload = false, progressCallback: (percent: number) => void): Promise<boolean> {
    let initialLoadCompleted = await this.storageService.getInitialLoadCompletedStatus();

    if (!initialLoadCompleted || forceReload) {
      // Download manifest and media from remote
      console.log('starting download of all content');
      initialLoadCompleted = await this.loadContentImpl(progressCallback); // download the content from the server
    } else { // initial load completed
      // Retrieve content from local storage (we have the media files already).
      this.setManifest(await this.storageService.getManifest());
    }
    this.initialLoadCompleted.next(initialLoadCompleted);
    return initialLoadCompleted;
  }

  // Convert date strings to date objects and lat,lng pairs to LatLng objects.
  private mapManifestFromResponse(manifest: IManifestDto): IManifest {
    if (manifest) {
      const pages: IPage[] = manifest.pages.map((page) => {

        // convert Lat,Long string to LatLng object which google maps understands.
        let latLng: LatLng = null;
        if (page.latLong && (page.latLong as string).indexOf(',') > 0) {
          const llArr = (page.latLong as string).split(',');
          latLng = { lat: Number(llArr[0]), lng: Number(llArr[1]) } as LatLng;
        }

        return { ...page,
          latLng,
          updateDate: new Date(page.updateDate),
        };
      });

      const media: IMedia[] = manifest.media.map((m) => {
        return {
          ...m,
          updateDate: new Date(m.updateDate),
          binaryFile: false,
          dataUrlPrefix: null,
          fileExtension: null,
          localUri: null,
          portrait: false
        };
      });

      const lastUpdated = new Date(manifest.lastUpdated);

      return { pages, media, lastUpdated} as IManifest;
    }
    else {
      return { lastUpdated: null, media: [], pages: []} as IManifest;
    }
  }

  // determine the image sizes we want our manifest to return based on our form factor.
  // small for mobile, medium for tablet, large for desktop
  getImageSizeForPlatform(): string {
    if (this.platform.is('desktop')) {
      return ImageSize.Desktop;
    }
    if (this.platform.is('tablet')) {
      return ImageSize.Tablet;
    }
    return ImageSize.Mobile; // default
  }

  /**
   * Sets the manifest in memory ready for use by the app
   * @param manifest the manifest that will be used by the live app
   */
  setManifest(manifest: IManifest) {
    // ensure date objects are actually objects and not just strings
    if (manifest.lastUpdated) {
      manifest.lastUpdated = new Date(manifest.lastUpdated);
    }
    if (manifest.media) {
      for (const item of manifest.media) {
        item.updateDate = new Date(item.updateDate);
      }
    }
    if (manifest.pages) {
      for (const item of manifest.pages) {
        item.updateDate = new Date(item.updateDate);
      }
    }
    this.manifest = manifest;
  }

  /**
   * download the content manifest and all media resources from the server.
   * Return the status of the download.
   */
  private async loadContentImpl(progressCallback: (percent: number) => void): Promise<boolean> {

    progressCallback(0);

    try {
      // load manifest
      this.setManifest(await this.downloadManifest());
    } catch (ex) {
      console.error('Error getting manifest file', ex);
      return false;
    }

    try {
      // now load media files
      await this.mediaLoaderService.downloadMediaFiles(this.manifest.media, false, progressCallback);

      // Store our manifest and mark our initial load as complete
      await this.storageService.setManifest(this.manifest);
      await this.storageService.setInitialLoadCompletedStatus(true);
      this.initialLoadCompleted.next(true);
      return true;

    } catch (ex) {
      console.error('Error getting media file', ex);
      return false;
    }
  }

  // trigger the refresh page flow process by navigating to the splash page if we are online
  public async triggerRefresh(event?: any) {
    if (this.networkService.isOnline()) {
      const params: NavigationExtras = {
        state: {
          forceRefresh: true,
          returnTo: window.location.pathname
        }
      };
      if (event) {
        event.target.complete();
      }
      this.navigationService.navigateToSystemPage(SystemPageTypes.Splash, params);
    } else {
      const toast = await this.toastController.create({
        color: 'warning',
        duration: 3000,
        message: 'Cannot refresh content when offline.',
        position: 'top',
        buttons: [
          { text: 'X', role: 'cancel'}
        ]
      });
      toast.present();

      if (event) {
        event.target.complete();
      }
    }
  }

  /**
   * Downloads the latest version of the mainfest from the server.
   * @param lastUpdated the date that the local app was last updated
   * @returns the updated manifest if there is one or null if no updates available.
   */
  public async downloadManifest(lastUpdated?: Date): Promise<IManifest> {

    let manifestUrl = this.manifestUrl;

    const imageSize = this.getImageSizeForPlatform();
    manifestUrl += '?image_size=' + imageSize;

    if (lastUpdated) {
      manifestUrl += '&last_updated=' + lastUpdated.toISOString();
    }

    const response = await this.httpClient.get<IManifestResponse>(manifestUrl).toPromise();
    if (!response.updatedManifestAvailable) {
      return null;
    } else {
      const newManifest = this.mapManifestFromResponse(response.manifest);
      console.log(`downloaded content manifest with ${newManifest.pages?.length} pages and ${newManifest.media?.length} media items`);
      return newManifest;
    }
  }

  /**
   * Perform an incremental refresh of the apps content
   * @param progressCallback progress indicator callback.
   */
  public async refreshContent(progressCallback: (percent: number) => void): Promise<RefreshStatus> {

    progressCallback(0);

    try {
      const currentManifest: IManifest = this.manifest;
      // Get a new manifest if one is available
      const newManifest: IManifest =  await this.downloadManifest(currentManifest.lastUpdated);
      if (!newManifest) {
        console.log('no updates available');
        return RefreshStatus.NoUpdates;
      }

      // We have a newer manifest - check this new manifest and see if it has new or updated media items.
      const changes = this.mediaLoaderService.getMediaChanges(currentManifest.media, newManifest.media);

      // load new media
      if (changes.downloads.length > 0) {
        await this.mediaLoaderService.downloadMediaFiles(changes.downloads, true, progressCallback);
      }

      // Get the updated manifest by merging the media updates into the already enriched current manifest's media
      for (const newOrUpdatedItem of changes.downloads) {
        const index = currentManifest.media.findIndex(currentItem => currentItem.id === newOrUpdatedItem.id);
        if (index > -1) {
          currentManifest.media[index] = {...newOrUpdatedItem};
        } else {
          currentManifest.media.push(newOrUpdatedItem);
        }
      }

      // Delete removed or updated media files
      if (changes.deletes.length > 0) {
        for (const deletedItem of changes.deletes) {
          try {
            await this.mediaLoaderService.deleteMediaFile(deletedItem);
            const index = currentManifest.media.findIndex(currentItem => currentItem.id === deletedItem.id);
            if (index > -1) {
              currentManifest.media.splice(index, 1);
            }
          } catch (ex) {
            console.log('unable to delete 1 or more media files... continuing');
          }
        }

      }

      // now merge in the new content and update date
      const mergedManifest: IManifest = {
        lastUpdated: newManifest.lastUpdated,
        media: currentManifest.media,
        pages: newManifest.pages
      };

      // Store our manifest and mark our initial load as complete
      this.setManifest(mergedManifest);
      await this.storageService.setManifest(mergedManifest);
      this.initialLoadCompleted.next(true);

      return RefreshStatus.UpdatesApplied;

    } catch (ex) {
      // If we have any exceptions from the refresh, we wont update the manifest - the Error return will trigger an app refresh
      // and the previous content will be loaded from local storage instead. ie we are treating the refresh as transactional
      // ie it works or fails in (pseudo) entirety. User can try again when they have better connectivity (or a content error is fixed?)
      console.error('Error getting media file', ex);
      return RefreshStatus.Error;
    }
  }
}
