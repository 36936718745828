import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Storage } from '@ionic/storage-angular';
import { IManifest } from '../../../app/models/manifest.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  public static InitialContentLoadCompleteKey = 'InitialContentLoadComplete';
  public static TermsAndConditionsAcceptedKey = 'TermsAndConditionsAccepted';
  public static ContentManifestKey = 'ContentManifest';
  public static AutoRefreshContentKey = 'AutoRefreshContent';
  public static AppVersionAtLastContentUpdateKey = 'AppVersionAtLastContentUpdate';
  public static PendingErrorMessageToastKey = 'PendingErrorMessageToast';

  constructor(private storage: Storage) {
  }

  async init() {
    if(this._storage != null) {
      return;
    }
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async get(key: string): Promise<string> {
    await this.init();
    const result = await this._storage.get(key);
    if (result) {
      return result;
    } else {
      return null;
    }
  }

  async set(key: string, value: string) {
    await this.init();
    await this._storage.set(key, value);
  }

  async remove(key: string) {
    await this.init();
    await this._storage.remove(key);
  }

  public async getInitialLoadCompletedStatus(): Promise<boolean> {
    return await this.get(StorageService.InitialContentLoadCompleteKey) === 'true' ? true : false;
  }

  public async setInitialLoadCompletedStatus(completed: boolean) {
    await this.set(StorageService.InitialContentLoadCompleteKey, completed ? 'true' : 'false');
  }

  public async setManifest(manifest: IManifest) {
    await this.set(StorageService.ContentManifestKey, JSON.stringify(manifest));
  }

  public async getManifest(): Promise<IManifest> {
    return JSON.parse(await this.get(StorageService.ContentManifestKey)) as IManifest;
  }

  public async getAutoRefreshContentStatus(): Promise<boolean> {
    const value = await this.get(StorageService.AutoRefreshContentKey);
    if (!value) {
      return Capacitor.isNative; // enabled by default for native device, otherwise disabled.
    } else {
      return value === 'true' ? true : false; // default to true if not found
    }
  }

  public async setAutoRefreshContentStatus(completed: boolean) {
    await this.set(StorageService.AutoRefreshContentKey, completed ? 'true' : 'false');
  }

  public async getPendingErrorMessageToast(): Promise<{ color: string; message: string; }> {
    const pendingMessageJson = await this.get(StorageService.PendingErrorMessageToastKey);
    if (pendingMessageJson) {
      return JSON.parse(pendingMessageJson);
    } else {
      return null;
    }
  }

  public async setPendingErrorMessageToast(details: { color: string; message: string; }) {
    if (details) {
      await this.set(StorageService.PendingErrorMessageToastKey, JSON.stringify(details));
    }
  }

  public async clearPendingErrorMessageToast() {
    await this.remove(StorageService.PendingErrorMessageToastKey);
  }

  public async getAppVersionAtLastContentUpdate(): Promise<string> {
    const value = await this.get(StorageService.AppVersionAtLastContentUpdateKey);
    if (!value) {
      return '0.0.0';
    } else {
      return value;
    }
  }

  public async setAppVersionAtLastContentUpdate(version: string) {
    await this.set(StorageService.AppVersionAtLastContentUpdateKey, version);
  }

}
