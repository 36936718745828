import { Injectable } from '@angular/core';
import { CanActivate, NavigationExtras } from '@angular/router';
import { ContentLoaderService } from './content-loader.service';
import { NavigationService } from '../navigation/navigation.service';
import { SystemPageTypes } from '../../models/system-page-types.enum';

@Injectable({
  providedIn: 'root'
})
export class ContentLoadedGuard implements CanActivate {

  constructor(
    private contentLoaderService: ContentLoaderService,
    private navigationService: NavigationService
  ) {}

  async canActivate() {
    const loaded = await this.contentLoaderService.checkInitialLoadCompleted();
    if (!loaded) {
      const params: NavigationExtras = {
        state: {
          returnTo: window.location.pathname  // handle deep links by returning to requested page after content loaded.
        }
      };
      this.navigationService.navigateToSystemPage(SystemPageTypes.Splash, params);
      return false;
    }
    return true;
  }
}
