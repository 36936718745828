import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { IPage } from '../../../app/models/page.model';
import { DocumentTypes } from '../../../app/models/document-types.enum';
import { SystemPageTypes } from '../../../app/models/system-page-types.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private router: Router
  ) { }

  public getPageUrl(page): string {
    let url = '';

    switch (page.documentType) {
      case DocumentTypes.HomePage:
        url = '';
        break;
      case DocumentTypes.LandingPage:
        url = `landing/${page.id}`;
        break;
      case DocumentTypes.InformationPage:
        url = `information/${page.id}`;
        break;
      case DocumentTypes.FormPage:
        url = `form/${page.id}`;
        break;
      case DocumentTypes.PointOfInterestPage:
        url = `point-of-interest/${page.id}`;
        break;
      case DocumentTypes.ItineraryPage:
        url = `itinerary/${page.id}`;
        break;
      case DocumentTypes.MapPage:
        url = `map`;
        break;
      case DocumentTypes.PhotoGalleryPage:
        url = `photo-gallery`;
        break;
      case DocumentTypes.SearchPage:
        url = `search`;
        break;
      default:
        break;
    }
    return url;
  }

  public navigateByUrl(url: string, navigationExtras?: NavigationExtras) {
    this.router.navigateByUrl(url, navigationExtras);
  }

  public navigateToContentPage(page: IPage) {
    this.router.navigateByUrl(this.getPageUrl(page));
  }

  public navigateToSystemPage(sysPage: SystemPageTypes, navigationExtras?: NavigationExtras) {
    this.router.navigateByUrl(sysPage, navigationExtras);
  }

  // will trigger a full app refresh to ensure all components used in app are redrawn
  public reloadApp(url: string = null) {
    if (url) {
      window.location.href = url;
    } else {
      window.location.href = '/home';
    }
  }

}
