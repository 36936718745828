import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private storageService: StorageService
    ) { }

  public addPendingErrorMessageToast(color: string, message: string) {
    this.storageService.setPendingErrorMessageToast({ color, message });
  }

  public async showDownloadAlert() {
    const alert = await this.alertController.create({
      cssClass: 'error-alert',
      header: 'Download Error',
      message: '<p>An error occurred downloading content for the app. Please check your connection and try again.</p>',
      buttons: ['Retry']
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  public async showPendingErrorMessageToast() {

    const pendingMessage = await this.storageService.getPendingErrorMessageToast();

    if (pendingMessage) {
      const toastMessage = pendingMessage.message;
      const toast = await this.toastController.create({
        color: pendingMessage.color,
        duration: 4000,
        message: toastMessage,
        position: 'top',
        buttons: [
          { text: 'X', role: 'cancel'}
        ]
      });
      toast.present();

      await this.storageService.clearPendingErrorMessageToast();
    }
  }


}
