import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ContentLoadedGuard } from './services/content/content-loaded.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'map',
    loadChildren: () => import('./components/map/map.module').then( m => m.MapPageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'point-of-interest/:id',
    loadChildren: () => import('./components/point-of-interest/point-of-interest.module').then( m => m.PointOfInterestPageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then( m => m.HomePageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'landing/:id',
    loadChildren: () => import('./components/landing/landing.module').then( m => m.LandingPageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'information/:id',
    loadChildren: () => import('./components/information/information.module').then( m => m.InformationPageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'itinerary/:id',
    loadChildren: () => import('./components/itinerary/itinerary.module').then( m => m.ItineraryPageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'photo-gallery',
    loadChildren: () => import('./components/photo-gallery/photo-gallery.module').then( m => m.PhotoGalleryPageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./components/search/search.module').then( m => m.SearchPageModule),
    canActivate: [ContentLoadedGuard]
  },
  {
    path: 'splash',
    loadChildren: () => import('./components/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: '**', redirectTo: '/splash', pathMatch: 'full'
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
